import { useEffect } from "react";
import {writeAnim} from './Item.js'

export default function LoadScreen({ message }) {
  useEffect(() => {
    writeAnim(message,  document.querySelector(".load-wrapper p"), 50)
  }, []);
  const pStyle = {
    maxWidth: "350px",
    textAlign: "center",
  };
  return (
    <div className="load-wrapper off df w100">
      <p className="blink-underscore" style={pStyle}></p>
    </div>
  );
}

export function LoadScreen2(msg, flag ){
  if(flag){
    const wrapper = document.createElement('div');
    wrapper.setAttribute('class', 'load-wrapper df w100');
    const message = document.createElement("p");
    message.setAttribute("class", "blink-underscore");

    wrapper.appendChild(message);
    document.body.appendChild(wrapper);
    writeAnim(msg, message, 50)
  }
  else {
    document.querySelector('.load-wrapper').remove();
  }


}
